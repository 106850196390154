<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">人员范围</div>
					<div class="inputs_box">
						<!-- <el-select v-model="user_range_select" placeholder="请选择">
							<el-option v-for="item in user_range_list" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select> -->

						<bumen_cascader ref="bumen_cascader" :is_show_position="true" @confirm="confirm_bumen_cascader_value" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">时间范围</div>
					<div class="inputs_box">
						<!-- clearable -->
						<!-- disabled -->
						<el-date-picker disabled v-model="month_select" value-format="yyyy-MM-dd" type="month" placeholder="选择月">
						</el-date-picker>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search()">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<!-- <div class="btns">
						导出礼品数据
					</div> -->
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55"></el-table-column> -->
					<!-- 	<el-table-column fixed prop="id" label="姓名" width="120"></el-table-column>
					<el-table-column prop="id" label="基本信息" width="120">
						<el-table-column prop="id" label="部门" width="120"></el-table-column>
						<el-table-column prop="id" label="岗位" width="120"></el-table-column>
						<el-table-column prop="id" label="考勤类别" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="出勤统计" width="120">
						<el-table-column prop="id" label="应出勤天数" width="120"></el-table-column>
						<el-table-column prop="id" label="实际出勤的天数" width="120"></el-table-column>
						<el-table-column prop="id" label="加班时长（记加班费）(小时)" width="200"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="异常统计" width="120">
						<el-table-column prop="id" label="迟到次数" width="120"></el-table-column>
						<el-table-column prop="id" label="迟到时长(分钟)" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="请假统计" width="120">
						<el-table-column prop="id" label="年假" width="120"></el-table-column>
						<el-table-column prop="id" label="其他请假" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="下井补助" width="120">
						<el-table-column prop="id" label="零点带班" width="120"></el-table-column>
						<el-table-column prop="id" label="四点带班" width="120"></el-table-column>
						<el-table-column prop="id" label="下井" width="120"></el-table-column>
						<el-table-column prop="id" label="登山次数" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="伙食补" width="120">
						<el-table-column prop="id" label="零点个数" width="120"></el-table-column>
						<el-table-column prop="id" label="八点个数" width="120"></el-table-column>
						<el-table-column prop="id" label="四点个数" width="120"></el-table-column>
					</el-table-column> -->




					<el-table-column fixed prop="name" label="姓名" width="120"></el-table-column>
					<el-table-column prop="" label="基本信息" width="360">
						<el-table-column prop="_bumen" label="部门" width="150"></el-table-column>
						<el-table-column prop="_position" label="岗位" width="120"></el-table-column>
						<!-- <el-table-column prop="id4" label="考勤类别" width="120"></el-table-column> -->
					</el-table-column>
					<el-table-column prop="" label="出勤统计" width="120">
						<el-table-column prop="workDay" label="应出勤天数" width="120"></el-table-column>
						<el-table-column prop="realDay" label="实际出勤的天数" width="120"></el-table-column>
						<el-table-column prop="overHour" label="加班时长（记加班费）(小时)" width="200"></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="异常统计" width="120">
						<el-table-column prop="lateNum" label="迟到次数" width="120"></el-table-column>
						<el-table-column prop="lateMinute" label="迟到时长(分钟)" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="请假统计" width="120">
						<el-table-column prop="yearHoliday" label="年休假" width="120"></el-table-column>
						<el-table-column prop="holiday" label="节日" width="120"></el-table-column>
						<el-table-column prop="sickLeave" label="病假" width="120"></el-table-column>
						<el-table-column prop="absenceLeave" label="事假" width="120"></el-table-column>
						<el-table-column prop="workLeave" label="工伤" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="" label="带班下井补助" width="120">
						<el-table-column prop="daiaban0" label="零点带班" width="120"></el-table-column>
						<el-table-column prop="daiaban4" label="四点带班" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="" label="下井补助" width="120">
						<el-table-column prop="xiajing" label="下井" width="120"></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="其他补助" width="120">
						<el-table-column prop="climbTime" label="登山次数" width="120"></el-table-column>
						<el-table-column prop="shangquTime" label="山区补助" width="120"></el-table-column>
					</el-table-column>



					<el-table-column prop="id" label="每日统计" width="120">
						<el-table-column v-for="(day_item,day_index) in date_list" prop="id"
							:label="day_item.date + ' ' + day_item.week" width="150">
							<template slot-scope="scope">
								<div class="date-grid" v-if="scope.row.dayFrom">
									<div class="start" v-if="scope.row.dayFrom[day_index]">
										<!-- 08:30 -->
										{{scope.row.dayFrom[day_index].checkInTime | format_daka_time}} -
										{{scope.row.dayFrom[day_index].checkOutTime |format_daka_time}}
									</div>
									<div class="end" v-if="scope.row.dayFrom[day_index]">
										<!-- 18:01 -->
										{{scope.row.dayFrom[day_index].checkInTime1 | format_daka_time}} -
										{{scope.row.dayFrom[day_index].checkOutTime1 | format_daka_time}}
									</div>
								</div>
							</template>

						</el-table-column>
					</el-table-column>



				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
	import dayjs from 'dayjs'

	import page from "@/components/page/page.vue"; //分页  统一的

	export default {
		components: {
			page,
		},
		data() {
			return {
				bumen_position_info: {

				},
				//
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					identity: '',
				},
				list: [

				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围	


				//
				//
				user_range_list: [

				],
				user_range_select: '',

				month_select: dayjs().format('YYYY-MM-DD'),
				date_list: [
					// {date: '2024-05-01', week: '星期日',},
				],
			};
		},
		computed: {},
		watch: {
			month_select(value) {
				console.log('month_select 月份选择', value)
			}
		},

		filters: {
			format_daka_time(str) {
				let time_format = (str || '').substring(11, 16) || ''
				return time_format
			}
		},

		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},
		methods: {
			confirm_bumen_cascader_value(value) {
				// this.$log('勾选部门数据', value)
			let departIds = '';

				if (typeof value[0] == 'object') {

					let last_id_arr = value.map(arr => arr[arr.length - 1])

					departIds = last_id_arr.join(',')

				} else {

					departIds = value[value.length - 1];

				}

				let departItems = JSON.stringify(value)

				// this.$log('departIds', departIds)
				// this.$log('departItems', departItems)

				this.bumen_position_info = {
					position: departIds
				}
			},

			//获取当前月份开始和结束日期
			get_date_info() {
				let start = dayjs(this.month_select).startOf('month').format('YYYY-MM-DD')
				let end = dayjs(this.month_select).endOf('month').format('YYYY-MM-DD')
				let day_number = parseInt(end.substring(8, 10))
				let info = {
					start,
					end,
					day_number,
				}

				console.log('时间信息 date_info', info)
				return info
			},


			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				// this.loading = true
				console.log('月份选择', this.month_select)
				let date_info = this.get_date_info()
				this.set_date_list();
				// return


				this.$api("kaoqin/getMonthList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					// start: date_info.start,
					// end: date_info.end,
					month: this.month_select, //日期
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list;
						list.forEach(v => {
							// v._name = user.name;
							let user = v;
							// v._bumen = user.depart_title || '';
							v._bumen = this.mix_format_bumen_title(user.depart_title) || '';
							v._position = user.level6;
						})
						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},


			set_date_list() {
				let month_value = this.month_select.substring(0, 7); //当前月份
				let date_info = this.get_date_info();
				let day_number = date_info.day_number || 30; //总天数
				let date_list = []
				for (var i = 1; i <= day_number; i++) {
					let day = i < 10 ? '0' + i : i;
					let date = `${month_value}-${day}`;
					let week_number = dayjs(date).day()
					let week_text = {
						0: '星期日',
						1: '星期一',
						2: '星期二',
						3: '星期三',
						4: '星期四',
						5: '星期五',
						6: '星期六',
					} [week_number]
					let info = {
						date: date,
						week: week_text,
					}
					date_list.push(info)
				}
				this.date_list = date_list;

				console.log('当前月份日期列表', JSON.parse(JSON.stringify(this.date_list)))
			}

		},


	};
</script>

<style scoped lang="less">
	.date-grid {
		text-align: center;
	}


	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>